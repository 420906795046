<template>
  <div class="bg-white">
    <b-container class="py-5">
        <h2 class="font-weight-bold">{{ $t('seller_signup_success.heading') }}</h2>
        <div class="d-flex flex-column flex-md-row justify-content-center my-5">
          <div class="d-flex flex-column flex-1 align-items-center mb-5 mb-md-0">
            <img v-lazy="require('@/assets/Connecting/connect-etn-icon.svg')" width="120" class="mx-auto mb-3">
            <h4 class="font-weight-bold">{{ $t('seller_signup_success.connect_etn.heading') }}</h4>
            <p class="w-75 px-4">{{ $t('seller_signup_success.connect_etn.copy') }}</p>
            <router-link :to="{name:'connecting-etn'}" rel="noopener">
              <button class="btn btn-electroneum px-4 d-flex justify-content-center">
                <span class="mr-3">{{ $t('connect') }}</span>
                <img v-lazy="require('@/assets/Logos/etn-cwp-white.svg')" width="100">
              </button>
            </router-link>
          </div>
          <div class="d-flex flex-column flex-1 align-items-center">
            <img v-lazy="require('@/assets/Connecting/create-draft-task-icon.svg')" width="120" class="mx-auto mb-3 mt-1">
            <h4 class="font-weight-bold">{{ $t('seller_signup_success.create_draft.heading') }}</h4>
            <p class="w-75" v-html="$t('seller_signup_success.create_draft.copy')"/>
            <router-link :to="{ name:'task_or_job', params: { referrer: 'seller_welcome' } }" rel="noopener">
              <button class="btn btn-primary px-5">
                {{ $t('seller_signup_success.create_draft.cta') }}
              </button>
            </router-link>
          </div>
        </div>
        <div class="w-100 text-align-center pt-1 pt-md-3">
          <router-link :to="'/'" rel="noopener" class="exploreLink">
            {{ $t('seller_signup_success.do_this_later') }}
          </router-link>
        </div>
        <SellerWelcomeModal ref="seller-welcome-modal"/>
    </b-container>
  </div>
</template>

<script>
import SellerWelcomeModal from '../../components/Modal/SellerWelcomeModal'
import ApiService from '../../apiService.js'
import { mapActions } from 'vuex'

export default {
  name: 'SellerWelcome',
  components: { SellerWelcomeModal },
  mounted () {
    this.$bvModal.show('seller-welcome-modal')
    this.setRole()
  },
  methods: {
    ...mapActions([
      'processJwt'
    ]),
    setRole () {
      this.processJwt().then(() => {
        if (!this.$store.getters.hasRole('ROLE_PRE_SELLER')) {
          ApiService.getPreSellerRole().then((dataUser) => {
            localStorage.setItem('accessToken', dataUser.data.data.token)
            this.processJwt()
          }).catch(() => {})
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.flex-1 {
  flex: 1;
}
.exploreLink {
  color: $brand-sky !important;
}

a {
  color: white;
  &:hover {
    text-decoration: none;
  }
  .btn {
    width: 100%;
  }
}

@include sm-up {
  .btn {
    width: auto;
  }
}

</style>
