<template>
  <div>
    <Confetti :show="display"/>
    <b-modal ref="seller-welcome-modal" hide-backdrop centered hide-header hide-footer id="seller-welcome-modal" class="d-flex justify-content-center p-3" @hidden="display = false">
      <span class="close position-absolute" @click="hide()">
        <i class="fa-solid fa-xmark" aria-hidden="true"></i>
      </span>
      <div class="icon mb-4 d-flex justify-content-center align-items-center">🎉</div>
      <h2 class="font-weight-bold mx-auto mb-4" v-html="$t('seller_signup_success_modal.heading')"></h2>
      <hr class="mx-5"/>
      <p class="text-center px-3 mt-4">{{ $t('seller_signup_success_modal.body') }}</p>
      <div class="d-flex flex-column flex-sm-row justify-content-center pb-0 pb-sm-4 pb-md-2 pt-4 px-0 px-sm-2 px-md-5 buttons">
        <b-button class="btn btn-primary px-5" @click="hide()">{{ $t('order_button_text') }}</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import Confetti from '../Global/Confetti'

export default {
  name: 'SellerWelcomeModal',
  components: { Confetti },
  data () {
    return {
      display: true
    }
  },
  methods: {
    hide () {
      this.display = false
      this.$refs['seller-welcome-modal'].hide()
    },
    show () {
      this.display = true
      this.$refs['seller-welcome-modal'].show()
    }
  }
}
</script>

<style lang="scss">
  #seller-welcome-modal {
    .close {
      top: 20px;
      right: 20px;
      cursor: hand;
    }
    .modal-body {
      padding: 1rem;
      background-image: url('~@/assets/Connecting/geo-shapes-1.svg');
      background-repeat: no-repeat;
      background-size: 75px;
      .cta {
        background: $brand-sky;
        border: $brand-sky;
      }
      .icon {
        width: 72px;
        height: 72px;
        font-size: 2rem;
        border-radius: 50%;
        background: linear-gradient(137deg, #EBBB50 1%, #F6DA52 98%);
        margin: -52px auto 0 auto;
      }
    }
    .modal-dialog {
      max-width: 450px;
    }
    .modal-content {
      border: 0;
      box-shadow: 0 10px 31px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
    }
  }

</style>
